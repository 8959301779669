const Contacts = () => {
  return (
    <main className="section">
      <div className="container">
        <h1 className="title-1">Contacts</h1>

        <ul className="content-list">
          <li className="content-list__item">
            <h2 className="title-2">Location</h2>
            <p>Nice, France</p>
          </li>
          <li className="content-list__item">
            <h2 className="title-2">Telegram / WhatsApp</h2>
            <p><a href="tel:+33659775805">+33 (659) 77-58-05</a></p>
          </li>
          <li className="content-list__item">
            <h2 className="title-2">Email</h2>
            <p><a href="mailto:dfilatov2@gmail.com">dfilatov2@gmail.com</a></p>
          </li>
          <li className="content-list__item">
            <h2 className="title-2">LinkedIN</h2>
            <p><a target="_blank" href="https://www.linkedin.com/in/denis-filatov-aa5343229/">Filatov Denis</a></p>
          </li>
        </ul>

      </div>
    </main>

  );
}

export default Contacts;