import "./style.css";
import GitHubIcon from "./gitHub-black.svg"
const BtnGitHub = ({link}) => {
  return (
    <a href={link} target="_blank" rel="noreferrer" className="btn-outline">
      <img src={GitHubIcon} alt=""/>
      GitHub repo
    </a>
  );
};

export default BtnGitHub;