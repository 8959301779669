import "./style.css";
import fb from "../../img/icons/fb.svg";
import instagram from "../../img/icons/instagram.svg";
import twitter from "../../img/icons/twitter.svg";
import git from "../../img/icons/gitHub.svg";
import linkedin from "../../img/icons/linkedIn.svg";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer__wrapper">
          <ul className="social">
            <li className="social__item"><a href="https://www.facebook.com/filatov00"><img src={fb} alt="Link"/></a></li>
            <li className="social__item"><a href="https://gitlab.skillbox.ru/denis_filatov_2/"><img src={git} alt="Link"/></a></li>
            <li className="social__item"><a href="https://www.linkedin.com/in/denis-filatov-aa5343229/"><img src={linkedin} alt="Link"/></a></li>
          </ul>
          <div className="copyright">
            <p>© 2024 Denis Filatov</p>
          </div>
        </div>
      </div>
    </footer>

  );
};

export default Footer;