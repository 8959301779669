import project01 from "../../img/projects/01.jpg";
import project01big from "../../img/projects/01-big.jpg";

import project02 from "../../img/projects/02.jpg";
import project02big from "../../img/projects/02-big.jpg";

import project03 from "../../img/projects/03.jpg";
import project03big from "../../img/projects/03-big.jpg";

import project04 from "../../img/projects/04.jpg";
import project04big from "../../img/projects/04-big.jpg";

import project05 from "../../img/projects/05.jpg";
import project05big from "../../img/projects/05-big.jpg";

import project06 from "../../img/projects/06.jpg";
import project06big from "../../img/projects/06-big.jpg";


const projects = [
  {
    id:1,
    title: 'Gaming Streaming Portal',
    skills: 'React, NodeJs, MongoDB',
    img: project01,
    imgBig: project01big,
    gitHubLink: 'https://github.com',
  },
  {
    id:2,
    title: 'Video Service',
    skills: 'React, PHP, MySql',
    img: project02,
    imgBig: project02big,
    gitHubLink: 'https://github.com',
  },
  {
    id:3,
    title: 'Video Portal',
    skills: 'React, NodeJs, MongoDB',
    img: project03,
    imgBig: project03big,
    gitHubLink: 'https://github.com',
  },
  {
    id:4,
    title: 'Dating-app',
    skills: 'React native',
    img: project04,
    imgBig: project04big,
  },
  {
    id:5,
    title: 'Landing',
    skills: 'HTML, SCSS, JS',
    img: project05,
    imgBig: project05big,
  },
  {
    id:6,
    title: 'Gaming Community',
    skills: 'React, PHP, MySQL',
    img: project06,
    imgBig: project06big,
  }
];

export {projects};